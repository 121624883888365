<template>
    <div></div>
</template>
<script>
export default {
    created(){
        this.$store.dispatch('auth/logout')
            .then( () => {
                this.$router.push( {name: "Home"} );
            }).catch( () =>  {
                this.$router.push( {name: "Home"} );
            });
    }
}
</script>